import React, { useEffect, useState } from 'react';
import { Typography, Avatar, message, Image, Button, Carousel } from 'antd';
import {
  ExclamationCircleOutlined,
  AlertFilled,
  CustomerServiceFilled,
  CheckOutlined,
  RightOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  LockOutlined,
  FileProtectOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import idCccdImg from '../../../assets/id_cccd.svg';
import profileImg from '../../../assets/profile.svg';
import creditcardImg from '../../../assets/creditcard.svg';
import signatureImg from '../../../assets/signature.svg';
import cameraImg from '../../../assets/camera.svg';
import '../MyInfomation/MyInfomation.scss';
import _ from 'lodash'
import api from '../../../api';

export default function MyInfomation(props) {
  const isNext = new URLSearchParams(props.location.search).get('isNext');
  const history = useHistory();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({})

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      const { data } = await api.get('/users/profile')

      if (data?.data) {
        setProfile(data.data)
      }
    } catch (error) {
      console.log('Get profile error: ', error)
    }
  }

  const btnNextOnclick = async () => {
    try {
      const { data } = await api.get('/users/profile')

      let info = data?.data?.info
      if (data?.data) {
        setProfile(data.data)
      }
      if (!_.isEmpty(info.id_card) && !_.isEmpty(info.base_info) && !_.isEmpty(info.chu_ky) && !_.isEmpty(info.bank) ) {
        history.push('/confirm-contract')
        return
      } else {
        message.error('Vui lòng cập nhật đủ thông tin!')
        return
      }
    } catch (error) {
      console.log('Get profile error: ', error)
    }
  }

  const _getParam = (href) => {
    if (isNext === true || isNext === 'true') {
      return href + '?isNext=true'
    }
    return href
  }

  return (
    <motion.div
      // initial={{ opacity: 0.3, y: 10 }}
      // animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push('/me')} >
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>
        
        <Typography.Text className="header-title">Thông tin của tôi</Typography.Text>
      </div>
      <div className='body__container my-info'>
        <div className='block-1'>
          {/* Thông tin CMND/CCCD */}
          <div className='body__container__item'
            onClick={() => history.push(_getParam('/step-one'))}
          >
            <div className='body__container__item__icon'>
              <Image
                width={20}
                height={20}
                src={idCccdImg}
              />
            </div>
            <div className='body__container__item__info'>
              <div className='body__container__item__title'>
                <Typography.Text className='body__container__item__main--title obligatory'>
                  Thông tin CMND/CCCD
                </Typography.Text>
                
                <Typography.Text className='body__container__item__satus'>
                  {_.isEmpty(profile?.info?.id_card) ? <span>Chưa hoàn thành</span> : <span className='info-active'>Hoàn thành</span>}
                </Typography.Text>
              </div>
              <div class="body__container__item__description">
                <Typography.Text className='body__container__item__description--text'>
                  Cho tôi biết tình hình hiện tại
                </Typography.Text>
              </div>
            </div>
          </div>
          {/* Thông tin cá nhân */}
          <div className='body__container__item'
            onClick={() => history.push(_getParam('/step-two'))}
          >
            <div className='body__container__item__icon'>
              <Image
                width={20}
                height={20}
                src={profileImg}
              />
            </div>
            <div className='body__container__item__info'>
              <div className='body__container__item__title'>
                <Typography.Text className='body__container__item__main--title obligatory'>
                  Thông tin cá nhân
                </Typography.Text>
                <Typography.Text className='body__container__item__satus'>
                  {_.isEmpty(profile?.info?.base_info) ? <span>Chưa hoàn thành</span> : <span className='info-active'>Hoàn thành</span>}
                </Typography.Text>
              </div>
              <div class="body__container__item__description">
                <Typography.Text className='body__container__item__description--text'>
                  Cho tôi biết tình hình hiện tại
                </Typography.Text>
              </div>
            </div>
          </div>
          {/* Nhập thẻ ngân hàng */}
          <div className='body__container__item'
            onClick={() => history.push(_getParam('/step-three'))}
          >
            <div className='body__container__item__icon'>
              <Image
                width={20}
                height={20}
                src={creditcardImg}
              />
            </div>
            <div className='body__container__item__info'>
              <div className='body__container__item__title'>
                <Typography.Text className='body__container__item__main--title obligatory'>
                  Nhập thẻ ngân hàng
                </Typography.Text>
                <Typography.Text className='body__container__item__satus'>
                  {_.isEmpty(profile?.info?.bank) ? <span>Chưa hoàn thành</span> : <span className='info-active'>Hoàn thành</span>}
                </Typography.Text>
              </div>
              <div class="body__container__item__description">
                <Typography.Text className='body__container__item__description--text'>
                  Thông tin tài khoản vay
                </Typography.Text>
              </div>
            </div>
          </div>
          {/* Chữ ký viết tay */}
          <div className='body__container__item'
            onClick={() => history.push(_getParam('/step-four'))}
          >
            <div className='body__container__item__icon'>
              <Image
                width={20}
                height={20}
                src={signatureImg}
              />
            </div>
            <div className='body__container__item__info'>
              <div className='body__container__item__title'>
                <Typography.Text className='body__container__item__main--title obligatory'>
                  Chữ ký viết tay
                </Typography.Text>
                <Typography.Text className='body__container__item__satus'>
                  {_.isEmpty(profile?.info?.chu_ky) ? <span>Chưa hoàn thành</span> : <span className='info-active'>Hoàn thành</span>}
                </Typography.Text>
              </div>
              <div class="body__container__item__description">
                <Typography.Text className='body__container__item__description--text'>
                  Ký tên trước khi vay
                </Typography.Text>
              </div>
            </div>
          </div>
          {/* Thông tin bổ sung */}
          <div className='body__container__item'
            onClick={() => history.push(_getParam('/step-five'))}
          >
            <div className='body__container__item__icon'>
              <Image
                width={20}
                height={20}
                src={cameraImg}
              />
            </div>
            <div className='body__container__item__info'>
              <div className='body__container__item__title'>
                <Typography.Text className='body__container__item__main--title'>
                  Thông tin bổ sung 
                </Typography.Text>
                <Typography.Text className='body__container__item__satus'>
                  {_.isEmpty(profile?.info?.bo_xung) ? <span>Chưa hoàn thành</span> : <span className='info-active'>Hoàn thành</span>}
                </Typography.Text>
              </div>
              <div class="body__container__item__description">
                <Typography.Text className='body__container__item__description--text'>
                  Tăng khả năng xét duyệt thành công
                </Typography.Text>
              </div>
            </div>
          </div>
        </div>
        <div className='block-2'>
          {
            isNext === true || isNext === 'true' &&
            <Button className='btn-confirm' onClick={btnNextOnclick}>Tiếp tục</Button>
          }
        </div>
      </div>
    </motion.div>
  );
}
