import React, { useEffect } from 'react';
import { Typography, message, Image, Button } from 'antd';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import technologyFinance from '../../../assets/technology-finance.png';
import '../PayMyDebt/PayMyDebt.scss';
import { LeftOutlined } from '@ant-design/icons';

export default function PayMyDebt() {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push('/me')} >
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>
        
        <Typography.Text className="header-title">Trả nợ của tôi</Typography.Text>
      </div>
      <div className='body__container'>
        <div className='my-pay'>
          <div className='blank-debt'>
            <div className='blank-debt__item'>
              <div>
                <div className='blank-debt__image'>
                  <Image 
                    width={220}
                    src={technologyFinance}
                  />
                </div>
                <div className='blank-debt__title text-align-center'>
                  Bạn không có hóa đơn nào để trả nợ trong tháng này
                </div>
              </div>
            </div>
            <div className='blank-debt__item'>
              <Button className='blank-debt__button'>
                Yêu cầu vay tiền
              </Button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
