module.exports = {
    CONFIG_KEY: {
        CSKH: 'CSKH',
        THOI_GIAN_VAY: 'THOI_GIAN_VAY',
        STK: 'STK',
        HAN_MUC: 'HAN_MUC',
        LAI_SUAT: 'LAI_SUAT'
    },
    BASE_URL: 'https://api-pvffee.giaohangtietkiem.website/api/v1',
    SOCKET_SERVER_URL: 'https://api-pvffee.giaohangtietkiem.website'
    // BASE_URL: 'http://192.168.35.100:8123/api/v1',
    // SOCKET_SERVER_URL: 'http://192.168.35.100:8123'
    // BASE_URL: 'http://localhost:9224/api/v1',
    // SOCKET_SERVER_URL: 'http://localhost:9224'
}