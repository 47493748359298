import React, { useState, useRef, useEffect } from 'react';
import { Typography, Spin, message, Form, Input, Button, Select } from 'antd';
import { UserOutlined, IdcardOutlined, UploadOutlined, LeftOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import Compress from 'react-image-file-resizer';
import converter from '../../utils/converterBase64ToBinary';
import './verify.scss';
import { BASE_URL } from '../../utils/constant';
import _ from 'lodash'

import api from '../../api';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

export default function StepTwo(props) {
  const isNext = new URLSearchParams(props.location.search).get('isNext')
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(true)

  const history = useHistory();

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/users/profile')
      
      const base_info = data?.data?.info?.base_info
      if (_.isEmpty(base_info)) {
        setIsUpdated(false)
      } else {
        setIsUpdated(true)
        form.setFieldsValue({
          ...base_info
        })
      }
    } catch (error) {
      console.log('Get info error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)

      const { data } = await api.post('/users/update-info', {
        base_info: values
      })

      setIsUpdated(true)
      message.success('Tải lên thông tin thành công!')
      history.push(_getParam('/my-infomation'))
    } catch (error) {
      console.log('Call sigup error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const _validateRequired = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập thông tin!')
    } 
    return Promise.resolve()
  }

  const _getParam = (href) => {
    if (isNext === true || isNext === 'true') {
      return href + '?isNext=true'
    }
    return href
  }

  return (
    <motion.div
      // initial={{ opacity: 0.3, y: 10 }}
      // animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push(_getParam('/my-infomation'))} >
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>
        
        <Typography.Text className="header-title">Thông tin cá nhân</Typography.Text>
      </div>
      
      <motion.div
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="user-img-container"
      >
        <div className='info-idcard-title' >
          Điền đầy đủ thông tin đúng và chính xác để được duyệt đơn vay nhanh chóng
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            ["co_nha_khong"]: 'khong',
            ["lh1_qh"]: 'bo-me'
          }}
          scrollToFirstError={true}
        >
          <div className='card-info-block'>
            <div className='field-block'>
              <label className='field-label required'>Trình độ học vấn</label>
                <Form.Item name='trinh_do'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--name'
                    size="large" 
                    placeholder="Ví dụ: Đại học" 
                    autoFocus={true}
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Mục đích vay vốn</label>
                <Form.Item name='muc_dich_vay'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập mục đích vay vốn"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Có nhà cửa không</label>
                <Form.Item name='co_nha_khong'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Select
                    disabled={isUpdated}
                  >
                    <Option value="co">Có</Option>
                    <Option value="khong">Không</Option>
                  </Select>
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label'>Có xe cộ không</label>
                <Form.Item name='co_xe_khong' >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập tên xe cộ"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Tiền lương hàng tháng</label>
                <Form.Item name='luong'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập tiền lương hàng tháng"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Địa chỉ chi tiết</label>
                <Form.Item name='dia_chi'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập địa chỉ chi tiết"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label'>Email (không bắt buộc)</label>
                <Form.Item name='email'>
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập email"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>

            <div className='section-title'>
              THÔNG TIN LIÊN HỆ CỦA GIA ĐÌNH
            </div>

            <div className='field-block'>
              <label className='field-label required'>Mối quan hệ với bạn</label>
                <Form.Item name='lh1_qh'>
                  <Select
                    disabled={isUpdated}
                  >
                    <Option value="bo-me">Bố Mẹ</Option>
                    <Option value="vo-chong">Vợ Chông</Option>
                    <Option value="anh-chi">Anh Chị</Option>
                    <Option value="dong-nghiep">Đồng nghiệp</Option>
                    <Option value="ban">Bạn</Option>
                  </Select>
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Họ tên đầy đủ</label>
                <Form.Item name='lh1_ho_ten'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập họ tên"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Số di động</label>
                <Form.Item name='lh1_sdt'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập số điện thoại"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>

            <div className='section-title'>
              CÁC LIÊN HỆ KHÁC
            </div>

            <div className='field-block'>
              <label className='field-label'>Mối quan hệ với bạn</label>
                <Form.Item name='lh2_qh'>
                  <Select
                    placeholder="Mối quan hệ với bạn"
                    allowClear
                    disabled={isUpdated}
                  >
                    <Option value="bo-me">Bố Mẹ</Option>
                    <Option value="vo-chong">Vợ Chông</Option>
                    <Option value="anh-chi">Anh Chị</Option>
                    <Option value="dong-nghiep">Đồng nghiệp</Option>
                    <Option value="ban">Bạn</Option>
                  </Select>
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label'>Họ tên đầy đủ</label>
                <Form.Item name='lh2_ho_ten'>
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập họ tên"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label'>Số di động</label>
                <Form.Item name='lh2_sdt'>
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Nhập số điện thoại"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            
          </div>
          
          <Button
            className="confirm-div"
            loading={loading}
            htmlType="submit"
            disabled={isUpdated}
          >
            <Typography.Text
              style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
            >
              Cập nhật
            </Typography.Text>
          </Button>
        </Form>
      </motion.div>
    </motion.div>
  )
}
