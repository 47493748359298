import { useEffect, useRef, useState } from 'react';
import './Login.scss';
import { Image, Typography, Input, Button, message, Col, Row, Form} from 'antd';
import { PhoneOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone, SecurityScanOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import isCorrectNumberPhone from '../../utils/isCorrectNumberPhone';
import api from '../../api';
import { useDispatch } from 'react-redux';
import { LOGIN_SUCCESS } from '../../redux/constants';
import { motion } from 'framer-motion';
import logo from '../../assets/logo-non-color.png';
import _buildings from '../../assets/buildings.jpg';
import DieuKhoan from '../../components/DieuKhoan';

import './Login.scss';
export default () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [stateCaptcha, setStateCaptcha] = useState('123654')

  let canvas = useRef(null)

  useEffect(() => {
    renderCaptcha()
  }, [])

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const { data } = await api.post('/auth/signup', {
        phone: values.phone,
        password: values.password,
      })
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('role', data.role)
      dispatch({ type: LOGIN_SUCCESS, payload: {} })
      message.success('Tạo tài khoản thành công.')
      history.push('/')
    } catch (error) {
      console.log('Call sigup error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  function renderCaptcha () {
    const context = canvas.current.getContext('2d')
    let showNum = []
    let canvasWinth = 110
    let canvasHeight = 40
    canvas.current.width = canvasWinth
    canvas.current.height = canvasHeight

    const sCode = '0,1,2,3,4,5,6,7,8,9'
    const saCode = sCode.split(',')
    const saCodeLen = saCode.length

    for (let i = 0; i < 5; i++) {
      let sIndex = Math.floor(Math.random()*saCodeLen);
      let sDeg = ((Math.random()-0.5)*20*Math.PI) / 180;
      let cTxt = saCode[sIndex];
      showNum[i] = cTxt.toLowerCase();
      let x = 10 + i*20;
      let y = 30 + Math.random()*4;
      context.font = 'bold 23px 微软雅黑';
      context.translate(x, y);
      context.rotate(sDeg);
  
      context.fillStyle = randomColor();
      context.fillText(cTxt, 0, 0);
  
      context.rotate(-sDeg);
      context.translate(-x, -y);
    }
    for (let i = 0; i <= 3; i++) {
      context.strokeStyle = randomColor();
      context.beginPath();
      context.moveTo(
        Math.random() * canvasWinth,
        Math.random() * canvasHeight
      );
      context.lineTo(
        Math.random() * canvasWinth,
        Math.random() * canvasHeight
      );
      context.stroke();
    }
    for (let i = 0; i < 30; i++) {
      context.strokeStyle = randomColor();
      context.beginPath();
      let x = Math.random() * canvasWinth;
      let y = Math.random() * canvasHeight;
      context.moveTo(x,y);
      context.lineTo(x+1, y+1);
      context.stroke();
    }
    const rightCode = showNum.join('');
    setStateCaptcha(rightCode)
  }

  function randomColor () {
    let r = Math.floor(Math.random()*256);
    let g = Math.floor(Math.random()*256);
    let b = Math.floor(Math.random()*256);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  const _validatePhone = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập số điện thoại!')
    } else if (!isCorrectNumberPhone(value)) {
      return Promise.reject('Số điện thoại không hợp lệ!')
    }
    return Promise.resolve()
  }

  const _validatePassword = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập mật khẩu!')
    } 
    return Promise.resolve()
  }

  const _validateCaptcha = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập captcha!')
    } else if (value !== stateCaptcha) {
      return Promise.reject('Captcha không chính xác!')
    }
    return Promise.resolve()
  }

  return (
    <div
      className="screen__container"
      style={{
        backgroundColor: `#f9fafb`,
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="screen__form__header">
      </div>
      <div className='screen__form__body'>
        <div className="screen__form__body--header">
          <Typography.Text className='screen__form__body--header--title'>
            Đăng ký tài khoản
          </Typography.Text>
        </div>
        <div className="form">
          <Form
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            // validateTrigger={null}
          >
            <div className="form-body">
              <Form.Item name='phone'
                rules={[
                  { validator: _validatePhone }
                ]}
              >
                <Input 
                  className='form-body__input form-body__input--phone'
                  size="large" 
                  placeholder="Vui lòng nhập số điện thoại" 
                  autoFocus={true}
                  prefix={<PhoneOutlined />} 
                />
              </Form.Item>

              <Form.Item name='password'
                rules={[
                  { validator: _validatePassword }
                ]}
              >
                <Input.Password 
                  className='form-body__input form-body__input--password'
                  size="large" 
                  placeholder="Vui lòng đặt mật khẩu từ 6-16 chữ số" 
                  type="password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  prefix={<LockOutlined />} 
                />
              </Form.Item>
              
              <div className='recaptcha__block'>
                <Form.Item name='captcha'
                  rules={[
                    { validator: _validateCaptcha }
                  ]}
                >
                  <Input 
                    className='form-body__input--recaptcha'
                    size="large" 
                    placeholder="Nhập mã xác minh" 
                    prefix={<SecurityScanOutlined />} 
                  />
                </Form.Item>
                <div className='recaptchaCode'>
                  <canvas onClick={renderCaptcha} ref={canvas}></canvas>
                </div>
              </div>

              <Button
                className="button__login"
                loading={loading}
                size="large"
                htmlType="submit"
              >
                <Typography.Text className='button__login--title'>
                  Đăng ký
                </Typography.Text>
              </Button>
            </div>
          </Form>
        </div>
        
        <div
          className="form-footer"
          onClick={() => history.push('/auth')}
        >
          <Typography.Link className='link'>
            Đã có tài khoản ? Đăng nhập ngay{' '}
          </Typography.Link>
          <Button
            className="button__register"
            size="large"
            onClick={() => history.push('/auth')}
          >
            <Typography.Text className='button__register--title'>
              Đăng nhập
            </Typography.Text>
          </Button>
        </div>
        
      </div>
    </div>
  );
};
