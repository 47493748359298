import { useState } from 'react';
import {
  HomeOutlined,
  UserOutlined,
  CreditCardOutlined,
  WechatOutlined
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';
import connectCSKH from '../utils/connectCSKH';
import { useSelector } from 'react-redux';

export default function Navigator({ pathname }) {
  const history = useHistory();
  const { status } = useSelector((state) => state._auth);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 50,
        padding: '0px 30px',
        borderTop: '1px solid #eee',
        paddingBottom: 0,
        background: '#fff',
      }}
    >
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={() => {
          history.push('/');
        }}
      >
        <HomeOutlined
          style={{
            fontSize: 20,
            color: pathname === '/' ? '#002dbf' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: pathname === '/' ? '#002dbf' : '#666',
          }}
        >
          Trang chủ
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 50,
          borderRadius: 25,
          minWidth: 60,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        onClick={connectCSKH}
      >
        <WechatOutlined
          style={{
            fontSize: 20,
            color: pathname === '/ht' ? '#002dbf' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: pathname === '/ht' ? '#002dbf' : '#666',
          }}
        >
          Hỗ trợ
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 50,
          borderRadius: 25,
          minWidth: 60,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        onClick={() => {
          history.push('/wallet');
        }}
      >
        <CreditCardOutlined
          style={{
            fontSize: 20,
            color: pathname === '/wallet' ? '#002dbf' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: pathname === '/wallet' ? '#002dbf' : '#666',
          }}
        >
          Ví tiền
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={() => {
          if(status) {
            history.push('/me')
          } else {
            history.push('/auth')
          }
        }}
      >
        <UserOutlined
          style={{
            fontSize: 20,
            color: ['/me', '/auth', '/auth/', '/signup'].includes(pathname) ? '#002dbf' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: ['/me', '/auth', '/auth/', '/signup'].includes(pathname) ? '#002dbf' : '#666',
          }}
        >
          {status ? 'Hồ sơ': 'Đăng nhập'}
        </Typography.Text>
      </motion.div>
    </div>
  );
}
