import { useEffect, useRef, useState } from 'react';
import './Login.scss';
import { Image, Typography, Input, Button, message, Col, Row, Form } from 'antd';
import { PhoneOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone, SecurityScanOutlined, LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import { useDispatch } from 'react-redux';
import _buildings from '../../assets/buildings.jpg';

import './Login.scss';
export default () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => { }, [])

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const { data } = await api.put('/auth/my-password', {
        ...values
      })

      if(data) {
        message.success('Đổi mật khẩu thành công!')
        history.push('/me')
      }
    } catch (error) {
      console.log('Update password failed: ', error);
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const _validatePassword = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập mật khẩu!')
    } else if (value.length < 6) {
      return Promise.reject('Mật khẩu tối thiểu 6 ký tự!')
    }
    return Promise.resolve()
  }

  const _validateNewPassword = async (_, value) => {
    const newPassword = form.getFieldValue('password')
    if (newPassword !== value) {
      return Promise.reject('Mật khẩu đã nhập không trùng nhau!')
    }
    
    return Promise.resolve()
  }

  return (
    <div
      className="screen__container"
      style={{
        backgroundColor: `#f9fafb`,
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push('/me')} >
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>

        <Typography.Text className="header-title">Đổi mật khẩu</Typography.Text>
      </div>
      <div className='screen__form__body'>
        <div className="screen__form__body--header">
          <Typography.Text className='screen__form__body--header--title'>
            Thay đổi mật khẩu
          </Typography.Text>
        </div>
        <div className="form">
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="form-body">
              <Form.Item name='old_password'
                rules={[
                  { validator: _validatePassword }
                ]}
              >
                <Input.Password
                  autoFocus={true}
                  className='form-body__input--password'
                  size="large"
                  placeholder="Vui lòng nhập mật khẩu cũ"
                  type="password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  prefix={<LockOutlined />}
                />
              </Form.Item>

              <Form.Item name='password'
                rules={[
                  { validator: _validatePassword }
                ]}
              >
                <Input.Password
                  className='form-body__input--password'
                  size="large"
                  placeholder="Vui lòng nhập mật khẩu mới"
                  type="password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  prefix={<LockOutlined />}
                />
              </Form.Item>

              <Form.Item name='re_password'
                rules={[
                  { validator: _validateNewPassword }
                ]}
              >
                <Input.Password
                  className='form-body__input--password'
                  size="large"
                  placeholder="Vui lòng nhập lại mật khẩu mới"
                  type="password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  prefix={<LockOutlined />}
                />
              </Form.Item>

              <Button
                className="button__login"
                loading={loading}
                size="large"
                htmlType="submit"
              >
                <Typography.Text className='button__login--title'>
                  Xác nhận
                </Typography.Text>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
