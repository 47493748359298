import { useState } from 'react';
import './Login.scss';
import { PhoneOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Image, Typography, Input, Button, message, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as authActions from '../../redux/actions/auth';
import logo from '../../assets/logo-non-color--login.png';
import _buildings from '../../assets/buildings.jpg';
import { motion } from 'framer-motion';
import api from '../../api';
import { LOGIN_SUCCESS } from '../../redux/constants';

export default (props) => {
  const queryPhone = new URLSearchParams(props.location.search).get('phone');
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    phone: queryPhone || '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  async function confirmLogin() {
    try {
      if (!state.phone || !state.password) {
        message.error('Không được để trống thông tin.')
        return
      }
  
      const { data } = await api.post('/auth/login', {
        phone: state.phone,
        password: state.password
      })
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('role', data.role)
      dispatch({ type: LOGIN_SUCCESS, payload: {} })
      
      history.push('/')
    } catch (error) {
      console.log('Login error: ', error)
    }
  }

  return (
    <div
      className="screen__container"
      style={{
        backgroundColor: `#f9fafb`,
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="screen__form__header">
      </div>
      <div className='screen__form__body'>
        <div className="screen__form__body--header">
          <Typography.Text className='screen__form__body--header--title'>
            Đăng nhập
          </Typography.Text>
        </div>
        <div className="form">
          <div className="form-body">
            <Input 
              className='form-body__input--phone login-input'
              size="large" 
              placeholder="Vui lòng nhập số điện thoại" 
              autoFocus={true}
              value={state.phone}
              onChange={(e) => setState({ ...state, phone: e.target.value })}
              prefix={<PhoneOutlined />} 
            />

            
            <Input.Password 
              className='form-body__input--password login-input'
              size="large" 
              placeholder="Vui lòng nhập mật khẩu" 
              type="password"
              value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              prefix={<LockOutlined />} 
            />

            <Button
              className="button__login"
              onClick={confirmLogin}
              loading={loading}
              size="large"
            >
              <Typography.Text className='button__login--title'>
                Đăng nhập
              </Typography.Text>
            </Button>
          </div>
        </div>
        <div
          className="form-footer"
          onClick={() => history.push('/auth/signup?phone=' + state.phone)}
        >
          <Typography.Link className='link'>
            Chưa có tài khoản ? Đăng ký ngay{' '}
          </Typography.Link>
          <Button
            className="button__register"
            size="large"
            onClick={() => history.push('/auth/signup?phone=' + state.phone)}
          >
            <Typography.Text className='button__register--title'>
              Đăng ký
            </Typography.Text>
          </Button>
        </div>
      </div>
    </div>
  );
};
