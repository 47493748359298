import React, { useState, useRef, useEffect } from 'react';
import { Typography, Spin, message, Form, Input, Button, Select } from 'antd';
import { UserOutlined, IdcardOutlined, UploadOutlined, LeftOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import Compress from 'react-image-file-resizer';
import converter from '../../utils/converterBase64ToBinary';
import './verify.scss';
import { BASE_URL } from '../../utils/constant';
import _ from 'lodash'

import api from '../../api';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

export default function StepThree(props) {
  const isNext = new URLSearchParams(props.location.search).get('isNext')
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(true)

  const history = useHistory();

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/users/profile')
      
      const bank = data?.data?.info?.bank
      if (_.isEmpty(bank)) {
        setIsUpdated(false)
      } else {
        setIsUpdated(true)
        form.setFieldsValue({
          ...bank
        })
      }
    } catch (error) {
      console.log('Get info error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)

      const { data } = await api.post('/users/update-info', {
        bank: values
      })

      setIsUpdated(true)
      message.success('Tải lên thông tin thành công!')
      history.push(_getParam('/my-infomation'))
    } catch (error) {
      console.log('Call sigup error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const _validateRequired = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập thông tin!')
    } 
    return Promise.resolve()
  }

  const _getParam = (href) => {
    if (isNext === true || isNext === 'true') {
      return href + '?isNext=true'
    }
    return href
  }

  return (
    <motion.div
      // initial={{ opacity: 0.3, y: 10 }}
      // animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push(_getParam('/my-infomation'))} >
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>
        
        <Typography.Text className="header-title">Thông tin thẻ ngân hàng</Typography.Text>
      </div>
      
      <motion.div
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="user-img-container"
      >
        <div className='info-idcard-title' >
          Điền đầy đủ thông tin đúng và chính xác để được duyệt đơn vay nhanh chóng
        </div>
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError={true}
        >
          <div className='card-info-block'>
            <div className='field-block'>
              <label className='field-label required'>Tên chủ thẻ</label>
                <Form.Item name='chu_the'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--name'
                    size="large" 
                    placeholder="Nhập tên đầy đủ của chủ thẻ" 
                    autoFocus={true}
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Tên ngân hàng</label>
                <Form.Item name='ngan_hang'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="VD: MB Bank"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Nhập số tài khoản ngân hàng</label>
                <Form.Item name='stk'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="VD: 88889999"
                    disabled={isUpdated}
                  />
                </Form.Item>
            </div>

            <div className='warning-title'>
              Chú ý: Thẻ ngân hàng nhập vào phải là thẻ trả trước (debit card). 
              Không sử dụng thẻ tín dụng (credit card).
            </div>
            
          </div>
          
          <Button
            className="confirm-div"
            loading={loading}
            htmlType="submit"
            disabled={isUpdated}
          >
            <Typography.Text
              style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
            >
              Cập nhật
            </Typography.Text>
          </Button>
        </Form>
      </motion.div>
    </motion.div>
  )
}
