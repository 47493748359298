import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  LeftOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  WifiOutlined,
  PayCircleOutlined,
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';

import {
  Typography,
  Slider,
  Table,
  Modal,
  Popconfirm,
  message,
  Image,
  Input,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _cardbg from '../../assets/bg-card.svg';
import _whitelogo from '../../assets/logo-non-color.png';
import api from '../../api';
import useNotification from '../../hooks/useNotification';
import * as actions from '../../redux/actions/auth';
import connectCSKH from '../../utils/connectCSKH';
import CurrencyInput from 'react-currency-input-field';
import walletImage from '../../assets/wallet.jpg';
import './Wallet.scss';
import moment from 'moment';
import _ from 'lodash'
import EmptyIcon from '../../assets/emptyIcon.svg'
import ErrorIcon from '../../assets/error-icon.svg'

const Empty = () => {
  return <div className='empty-container'>
    <img src={EmptyIcon} alt='' />
    <div className='empty-text'>Bạn chưa có yêu cầu rút tiền nào</div>
  </div>
}

export default function Wallet(props) {
  const request = new URLSearchParams(props.location.search).get('request');
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const { profile } = useSelector((state) => state._auth);
  const { notifications, pushNotifications } = useNotification();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentRequest, setCurrentRequest] = useState({});
  const [OTP, setOTP] = useState('');
  const [visibleModalOtp, setVisibleModalOtp] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)
  const [contract, setContract] = useState({});
  const [messageError, setMessageError] = useState('')
  const [visibleModal, setVisibleModal] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadData()
  }, [notifications]);

  const requestBtnOnclick = async () => {
    if (contract?.status !== 'accepted') {
      message.error('Bạn chưa có hợp đồng vay được duyệt, không thể thực hiện rút tiền!')
      return
    }

    if (!profile?.balance > 0) {
      message.error('Số dư tài khoản không đủ!')
      return
    }

    const { data: lastRequest } = await api.get('/requests/lasted')
    setCurrentRequest(lastRequest?.data ? lastRequest.data : {})
    if (lastRequest?.data && lastRequest?.data?.status === 'pending') {
      setVisibleModalOtp(true)
      return
    }

    history.push('/create-request')
  }

  const loadData = async () => {
    try {
      const { data } = await api.get('/users/profile')
      const { data: contractData } = await api.get('/contracts/my-contract')

      dispatch(actions.initialLogin(data.data))
      
      if (contractData) {
        setContract(contractData)
        if (['accepted', 'settled'].includes(contractData.status)) {
          setIsEmpty(false)
        }
      }
      
      loadCurrentRequest()
    } catch (error) {
      console.log(error)
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const { data: lastRequest } = await api.get('/requests/lasted')
      setCurrentRequest(lastRequest?.data ? lastRequest.data : {})
      if (lastRequest?.data && lastRequest?.data?.status === 'pending') {
        setVisibleModalOtp(true)
        return
      }

      const { data: dataProfile } = await api.get('/users/profile')

      const {data: requestResult} = await api.post('/requests', {
        amount: parseInt(dataProfile?.data?.balance), //< parseInt(contractData?.amount) ? parseInt(dataProfile?.data?.balance) : parseInt(contractData?.amount),
        bank_reciever: {
          chu_the: profile?.info?.bank?.chu_the,
          stk: profile?.info?.bank?.stk,
          ngan_hang: profile?.info?.bank?.ngan_hang,
        },
      })

      // if(requestResult?.data?.error) {
      //   setMessageError(requestResult?.data?.error)
      //   setVisibleModal(true)
      // }
      setCurrentRequest(requestResult?.data)
      setVisibleModalOtp(true)
    } catch (error) {
      console.log('Call sigup error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const loadCurrentRequest = async () => {
    try {
      const { data } = await api.get('/requests/lasted');
      setCurrentRequest(data?.data ? data.data : {})
    } catch (error) {
      setCurrentRequest({})
      console.log(error);
    }
  }

  const onClickVerify = async (e) => {
    try {
      const { data: requestResult } = await api.post('/requests/verify', {
        id: currentRequest._id,
        otp: OTP
      })
      
      setVisibleModalOtp(false)
      setMessageError(requestResult?.data?.error)
      setVisibleModal(true)
    } catch (error) {
      console.log(error);
    } finally {
      setOTP('')
    }
  };

  const onClosePopup = () => {
    setVisibleModal(false)
    history.push('/wallet?request=true')
    loadCurrentRequest()
  }

  const [isConfirm, setIsConfirm] = useState(false);
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        {
          request == 'true' &&
          <span className='header-btn-back' onClick={() => history.push('/wallet')} >
            <LeftOutlined className="arrow-icon" />
            <span>Quay lại</span>
          </span>
        }
        
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: '#fff' }}
        >
          Ví tiền
        </Typography.Text>
      </div>
      <div className='request-container'>
        {
          request != 'true' &&
          <Card
            data={profile}
            balance={profile?.balance}
            requestBtnOnclick={requestBtnOnclick}
          />
        }
        {
          isEmpty ?
            <Empty />
            :
            <>
              <div className='content-wallet-loan'>
                <div className='contract-block'>
                  <div className='contract-code'>
                    <div className='ct-title'>
                      MÃ HỢP ĐỒNG VAY
                    </div>
                    <div className='content'>
                      {contract?.slug}
                    </div>
                  </div>
                  <div className='contract-amount'>
                    <div className='ct-title'>
                      SỐ TIỀN VAY
                    </div>
                    <div className='content'>
                      {profile?.balance?.toLocaleString('vi')}
                    </div>
                  </div>
                </div>
                <div className='btn-rut-tien' onClick={onFinish}>
                  <i className="fa-solid fa-circle-dollar-to-slot"></i>
                  <span>Rút tiền về tài khoản liên kết</span>
                </div>
              </div>
              {
                currentRequest && request == 'true' && currentRequest.status === 'rejected' &&
                  <div className='content-wallet-loan'>
                    <div className='request-block-1'>
                      <i class="fa-solid fa-circle"></i>
                      Yêu cầu bị từ chối
                    </div>
                    <div className='request-block-2'>
                      {currentRequest.error}
                    </div>
                  </div>
              }
            </>
        }
      </div>

      {
        !_.isEmpty(currentRequest) &&
          <div className='request-detail' style={{padding: '5px 16px 20px'}}>
            <div className="title-detail">Chi Tiết Giải Ngân</div>
            <table>
              <tr>
                <td>Thời gian rút tiền</td>
                <td>{moment(currentRequest.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
              </tr>
              <tr>
                <td>Thực rút về tài khoản</td>
                <td>{currentRequest.amount ? currentRequest.amount.toLocaleString() + ' VND' : ''}</td>
              </tr>
              <tr>
                <td>Trạng thái rút tiền</td>
                <td>{renderStatus(currentRequest.status)}</td>
              </tr>
              <tr>
                <td>Ghi chú</td>
                <td>{currentRequest.error}</td>
              </tr>
            </table>
          </div>
        }

      <div>
        <img style={{width: '100%', marginBottom: '60px'}} src={walletImage} alt='' />
      </div>

      <Modal
        visible={visibleModal}
        title={''}
        footer={null}
        closable={true}
        onCancel={onClosePopup}
        destroyOnClose
        className='model-error'
      >
        <div className="withdraw-money-container">
          <div
            className='error-container'
          >
            <img src={ErrorIcon} alt='' />
            <div className='error-content'>
              {messageError}
            </div>
            <div
              className='btn-close-error'
              loading={loading}
              onClick={onClosePopup}
            >
              OK
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibleModalOtp}
        title={'Nhập mã OTP'}
        footer={null}
        closable={true}
        onCancel={() => {
          setVisibleModalOtp(false)
        }}
        destroyOnClose
        className='model-otp'
      >
        <div
          className='otp-container'
        >
          <Input value={OTP} onChange={(e) => setOTP(e.target.value)} type='number' placeholder='Vui lòng nhập OTP' className='text-otp'/>
          <div
            className='btn-verify-otp'
            loading={loading}
            onClick={onClickVerify}
          >
            Xác nhận
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}

function Card({ data, balance, requestBtnOnclick }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState(0);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={requestBtnOnclick}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            borderRadius: 16,
            padding: 16,
            width: '100%',
            backgroundImage: `url(${_cardbg})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="card-header">
            Số dư ví
          </div>
          <div className='card-amout'>
            {(balance || 0).toLocaleString('vi')}<span style={{ textDecoration: 'underline' }}>đ</span>
          </div>
          <div className='card-fullname'>
            {data?.info?.id_card?.fullname || 'Chưa đăng ký'}
          </div>
        </div>
      </div>

      <Modal
        visible={visible}
        title={'Rút tiền'}
        footer={null}
        closable={true}
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <div className="withdraw-money-container">
          <CurrencyInput
            className="input-currency"
            min={0}
            max={balance}
            placeholder="Nhập số tiền rút"
            decimalsLimit={2}
            onValueChange={(value, name) => setAmount(parseInt(value))}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={() => {
                if (amount > data?.balance) {
                  message.info('Không thể cao hơn mức khả dụng');
                  return;
                }

                setVisible(false);
              }}
            >
              <Typography.Text
                style={{ color: '#fff', fontWeight: 700, fontSize: 16 }}
              >
                Tiếp tục
              </Typography.Text>
            </motion.div>
          </div>
        </div>
      </Modal>
    </motion.div>
  )
}
const Item = ({ text = 'title', icon, onClick = () => { } }) => {
  return (
    <div className="item" onClick={onClick}>
      <Typography.Text
        style={{ flex: 1, fontSize: 16, paddingLeft: 20, color: '#fff' }}
        strong
      >
        {text}
      </Typography.Text>
      <VerticalAlignBottomOutlined style={{ color: '#fff', fontSize: 30 }} />
    </div>
  );
};

const renderStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Đang xét duyệt'
    case 'accepted':
      return 'Thành công'
    case 'rejected':
      return 'Bị từ chối'
  }
  return ''
}