import React, { useState, useEffect } from 'react';
import { Badge, Typography, Carousel, Image, Button, Slider, Radio, message, Modal, Spin } from 'antd';
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';
import tickAsset from '../../assets/tick.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import home from '../../assets/banner.jpg';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { CONFIG_KEY } from '../../utils/constant';
import LogoImg from '../../assets/logo.png'
import _ from 'lodash';

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile, status } = useSelector((state) => state._auth);

  const [amount, setAmount] = useState(30)
  const [phoneNotifi, setPhoneNotifi] = useState([]);
  const [listMonth, setListMonth] = useState([])
  const [month, setMonth] = useState(6)
  const [rate, setRate] = useState(0.7)
  const [notModal, setNotModal] = useState(false);
  const [errorType, setErrorType] = useState(1);
  const [limit, setLimit] = useState({
    min: 30,
    max: 1000
  })
  const [banner, setBanner] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadData()
  }, [])

  const getRound = (value) => {
    if (Number.isInteger(value) && value >= 1000000) {
      return value/1000000
    }
    return 0
  }

  const loadData = async () => {
    try {
      setLoading(true)
      const { data: dataConfig } = await api.get('/config/all')
      const { data: dataBanner } = await api.get('/banner/all')
      const { data: dataPhone } = await api.get('/phone/all')

      if (dataConfig?.data?.length) {
        let dataMonth = dataConfig.data.find(item => item.key === CONFIG_KEY.THOI_GIAN_VAY)?.value || []
        setListMonth(dataMonth.sort((a, b) => a-b))
        
        const dataLimit = dataConfig.data.find(item => item.key === CONFIG_KEY.HAN_MUC)?.value
        if (dataLimit.min && dataLimit.max) {
          setLimit({
            min: getRound(dataLimit.min),
            max: getRound(dataLimit.max)
          })
          setAmount(getRound(dataLimit.min) || 30)
        }
        
        const dataRate = dataConfig.data.find(item => item.key === CONFIG_KEY.LAI_SUAT)?.value
        if (dataRate?.rate) {
          setRate(dataRate.rate)
        }
      }
      
      if (dataBanner?.data?.length) {
        setBanner(dataBanner.data)
      }
      
      if (dataPhone?.data?.length) {
        if (dataPhone?.data?.length < 5) {
          dataPhone.data = dataPhone.data.concat(dataPhone.data, dataPhone.data)
          dataPhone.data = dataPhone.data.concat(dataPhone.data, dataPhone.data)
        }
        setPhoneNotifi(dataPhone.data)
      }

    } catch (error) {
      console.log('Load config error: ', error);
    } finally {
      setLoading(false)
    }
  }

  const onChangeAmount = (newValue) => {
    setAmount(newValue)
  }

  const onAddAmount = () => {
    if (amount < limit.max) {
      setAmount(current => current + 1)
    }
  }

  const onSubtractAmount = () => {
    if (amount > limit.min) {
      setAmount(current => current - 1)
    }
  }

  const onConfirm = async () => {
    try {
      if(!status) {
        history.push('/auth')
        return
      }
      
      if (isNaN(amount) || amount < limit.min || amount > limit.max) {
        message.error(`Hạn mức vay trong khoảng ${limit.min.toLocaleString('vi')} đến ${limit.max.toLocaleString('vi')} đồng!`)
        return
      }
  
      if (isNaN(month)) {
        message.error(`Tháng đã chọn không hợp lệ!`)
        return
      }
  
      const { data: contractData } = await api.post('/contracts', {
        times: month,
        amount: amount * 1000000,
        rate: rate,
        amount_per_month: Math.round((amount * 1000000 / month) + (amount * 1000000 * rate/100))
      })
  
      dispatch({
        type: 'DISPATCH_CONTRACT',
        payload: { contract: contractData },
      })
  
      setLoading(true)
      // /confirm-contract
      const { data } = await api.get('/users/profile')
      if (data?.data?.info) {
        const info = data?.data?.info
        if (!_.isEmpty(info.id_card) && !_.isEmpty(info.base_info) && !_.isEmpty(info.chu_ky) && !_.isEmpty(info.bank) ) {
          history.push('/confirm-contract')
        } else {
          message.success('Đăng ký thành công, vui lòng cập nhật thêm thông tin!')
          history.push('/my-infomation?isNext=true')
        }
      } else {
        message.success('Đăng ký thành công, vui lòng cập nhật thêm thông tin!')
        history.push('/my-infomation?isNext=true')
      }
    } catch (error) {
      console.log("Create contract error: ", error)
    } finally {
      setLoading(false)
    }
  }

  const nextOnclick = () => {
    history.push('/my-infomation')
  }

  const nextWalletIcon = () => {
    history.push('/wallet')
  }

  return (
    <Spin spinning={loading}>
      <motion.div
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="container"
      >
        <div className="header-content">
          <div>
            <img className='logo' src={LogoImg} alt='loading...' />
          </div>
          <div onClick={nextWalletIcon}>
            <i className="fa-solid fa-wallet"></i>
            <span style={{color: '#000', fontWeight: 400}}> {profile?.balance || 0}</span>
            <span style={{color: '#000', fontWeight: 400}}>₫</span>
          </div>
        </div>

        <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
          {
            banner.map((item, index) => {
              return <div>
                <div key={index} className="carousel" style={{backgroundImage: `url(${item.url})`}}>
                  <div className="sl-text-container"></div>
                </div>
              </div>
            })
          }
        </Carousel>

        <div className='content-action'>
          <div className='action'>
            <img src='https://long-thanh.com/images/43f92d347d8da75d8b5a91c79fff1e3f-BigSavings.svg' alt='' />
            <div className='action-title'>Tiết kiệm lớn</div>
          </div>
          <div className='action'>
            <img src='https://long-thanh.com/images/ca2298978eba906df3c82237013616f2-RateIcon.svg' alt='' />
            <div className='action-title'>Lãi suất thấp</div>
          </div>
          <div className='action'>
            <img src='https://long-thanh.com/images/5391f7855d450b35a83f3b19ddbe6699-Giaingan.svg' alt='' />
            <div className='action-title'>Giải ngân nhanh</div>
          </div>
        </div>

        <div className='home-notification'>
          <div className='content-notification'>
          <Carousel dots={false} slidesToShow={4} dotPosition='right' autoplay autoplaySpeed={2000} className="carousel-phone-container">
            {
              phoneNotifi.map((noti, index) => {
                return (
                  <div className='content-item' key={index}>
                    <i className="fa-solid fa-play"></i>
                    Chúc mừng: <span className='phone'>{noti?.phone?.substring(0, 5) + '***' + noti?.phone?.substring(8)}</span> đã rút thành công <span className='amount'>{noti.amount.toLocaleString('vi')} ₫</span>
                  </div>
                )
              })
            }
            </Carousel>
          </div>
        </div>

        <div
          className='user-select-none'
        >
          <div className='home-money-change'>
            <div className='money-title'>TỔNG SỐ TIỀN VAY</div>
            <div className='money-amount'>{amount}.000.000đ</div>
            <div className='step-bar'>
              <i className="fa-solid fa-circle-minus" onClick={onSubtractAmount}></i>
              <Slider
                className='step-slider'
                min={limit.min}
                max={limit.max}
                tooltip={{ formatter: null }}
                onChange={onChangeAmount}
                value={typeof amount === 'number' ? amount : 30}
                step={1}
              />
              <i className="fa-solid fa-circle-plus" onClick={onAddAmount}></i>
            </div>
          </div>
          <div className='time-line-money-change'>
            <div className='time-tile'>
              THỜI GIAN CHO VAY
            </div>
            <div className='time-item-block'>
              <Radio.Group className='time-item-group' value={month} onChange={e => setMonth(e.target.value)} buttonStyle="solid">
              {
                listMonth.map((item, index) => {
                  return <div key={index}>
                    <Radio.Button className='time-item' value={item}>
                      <span className='item-text'>{item} tháng</span>
                    </Radio.Button>
                  </div>
                })
              }
              </Radio.Group>
            </div>
          </div>
          <div className='info-interest-block'>
            <div>
              <span className='interest-title'>Trả nợ định kỳ: </span>
              <span>{Math.round((amount * 1000000 / month) + (amount * 1000000 * rate/100)).toLocaleString('vi')} đ</span>
            </div>
            <div>(Bao gồm số tiền lãi {(amount * 1000000 * rate/100).toLocaleString('vi')} đ)</div>
          </div>
          <div className='button-custom-block'>
            <Button
              className="button-custom"
              onClick={onConfirm}
            >
              <Typography.Text
                style={{ color: '#fff', fontWeight: '600', fontSize: 16 }}
              >
                Nộp đơn nhanh
              </Typography.Text>
            </Button>
          </div>
        </div>

        <Modal
            title={null}
            footer={null}
            visible={notModal}
            closable={false}
            className='home-model'
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#002dbf',
              }}
            >
              <Typography.Text style={{ color: '#fff', fontSize: 17 }} strong>
                Thông báo
              </Typography.Text>
            </div>
            <br />
            <div className='model-content'>
              {
                _renderMessage(errorType)
              }
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                paddingTop: 30,
              }}
            >
              <motion.div
                whileTap={{ opacity: 0.4, scale: 0.97 }}
                style={{
                  background: '#888',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 10,
                  padding: '0px 10px',
                }}
                onClick={() => setNotModal(false)}
              >
                <Typography.Text
                  style={{ fontSize: 14, color: '#fff', fontWeight: 'bold' }}
                >
                  Đóng
                </Typography.Text>
              </motion.div>

              <motion.div
                whileTap={{ opacity: 0.4, scale: 0.97 }}
                style={{
                  background: '#002dbf',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 10,
                  padding: '0px 10px',
                }}
                onClick={nextOnclick}
              >
                <Typography.Text
                  style={{ color: '#fff', fontWeight: 400, fontSize: 14 }}
                >
                  Tiếp tục
                </Typography.Text>
              </motion.div>
            </div>
          </Modal>
        
      </motion.div>
    </Spin>
  );
}

const _renderMessage = (code) => {
  switch (code) {
    case 1:
      return <span>Bạn đã đăng ký vay, vui lòng nhấn tiếp tục để hoàn thiện hồ sơ vay!</span>
    default:
      return <span>Hồ sơ vay của bạn đã được duyệt, bấm tiếp tục để rút tiền!</span>
  }
}