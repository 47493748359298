import React, { useState, useEffect } from 'react';
import { Typography, message, Form, Input, Button, Modal } from 'antd';
import { LeftOutlined,  } from '@ant-design/icons';
import { motion } from 'framer-motion';
import './Wallet.scss';
import _ from 'lodash'
import ErrorIcon from '../../assets/error-icon.svg'

import api from '../../api';
import { useHistory } from 'react-router-dom';

export default function StepThree() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState({})
  const [messageError, setMessageError] = useState('')
  const [visibleModal, setVisibleModal] = useState(false)
  const [OTP, setOTP] = useState('');
  const [visibleModalOtp, setVisibleModalOtp] = useState(false)
  const [currentRequest, setCurrentRequest] = useState({})

  const history = useHistory();

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/users/profile')

      if (data?.data) {
        setProfile(data.data)
      }
    } catch (error) {
      console.log('Get contract error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const { data: lastRequest } = await api.get('/requests/lasted')
      setCurrentRequest(lastRequest?.data ? lastRequest.data : {})
      if (lastRequest?.data && lastRequest?.data?.status === 'pending') {
        setVisibleModalOtp(true)
        return
      }

      const {data: requestResult} = await api.post('/requests', {
        amount: parseInt(values.amount),
        bank_reciever: {
          chu_the: profile?.info?.bank?.chu_the,
          stk: profile?.info?.bank?.stk,
          ngan_hang: profile?.info?.bank?.ngan_hang,
        },
      })

      setCurrentRequest(requestResult?.data)
      setVisibleModalOtp(true)
    } catch (error) {
      console.log('Call sigup error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const onClickVerify = async (e) => {
    try {
      const { data: requestResult } = await api.post('/requests/verify', {
        id: currentRequest._id,
        otp: OTP
      })
      
      setVisibleModalOtp(false)
      setMessageError(requestResult?.data?.error)
      setVisibleModal(true)
    } catch (error) {
      console.log(error);
    } finally {
      setOTP('')
    }
  }

  const _validateRequestAmount = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập số tiền!')
    } else if (!Number.isInteger(parseFloat(value))) {
      return Promise.reject('Số tiền đã nhập không hợp lệ!')
    }
    return Promise.resolve()
  }

  return (
    <motion.div
      // initial={{ opacity: 0.3, y: 10 }}
      // animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push('/wallet')} >
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>
        
        <Typography.Text className="header-title">Yêu cầu rút tiền</Typography.Text>
      </div>
      
      <motion.div
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="extend-padding"
      >
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="request-form-container"
        >
          <div className='card-info-block'>
            <div className='field-block'>
              <label className='field-label required'>Nhập số tiền rút</label>
                <Form.Item name='amount'
                  rules={[
                    { validator: _validateRequestAmount }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--name'
                    size="large" 
                    placeholder="Nhập số tiền" 
                    autoFocus={true}
                  />
                </Form.Item>
            </div>

            <div className='info-bank-title'>
              Thông tin ngân hàng nhận tiền
            </div>

            <div className='bank-info-container'>
              <div className='bank-info__block'>
                {/* Tên ngân hàng */}
                <div className='bank-info__item'>
                  <div>
                    <div className='bank-info__item--title'>
                      Tên ngân hàng:
                    </div>
                    <div className='bank-info__item--data'>
                      {profile?.info?.bank?.ngan_hang || 'Chưa đăng ký'}
                    </div>
                  </div>
                </div>
                {/* Tài khoản ngân hàng */}
                <div className='bank-info__item'>
                  <div>
                    <div className='bank-info__item--title'>
                      Tài khoản ngân hàng:
                    </div>
                    <div className='bank-info__item--data'>
                      {profile?.info?.bank?.stk || 'Chưa đăng ký'}
                    </div>
                  </div>
                </div>
                {/* Tên người nhận chuyển khoản */}
                <div className='bank-info__item'>
                  <div>
                    <div className='bank-info__item--title'>
                      Tên người nhận chuyển khoản:
                    </div>
                    <div className='bank-info__item--data'>
                      {profile?.info?.bank?.chu_the || 'Chưa đăng ký'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <Button
            className="btn-request"
            loading={loading}
            htmlType="submit"
          >
            <i className="fa-solid fa-circle-dollar-to-slot"></i>
            <Typography.Text
              style={{ color: '#fff', fontWeight: 500, fontSize: 15 }}
            >
              Gửi yêu cầu
            </Typography.Text>
          </Button>
        </Form>
      </motion.div>

      <Modal
        visible={visibleModal}
        title={''}
        footer={null}
        closable={true}
        onCancel={() => history.push('/wallet?request=true')}
        destroyOnClose
        className='model-error'
      >
        <div className="withdraw-money-container">
          <div
            className='error-container'
          >
            <img src={ErrorIcon} alt='' />
            <div className='error-content'>
              {messageError}
            </div>
            <div
              className='btn-close-error'
              onClick={() => history.push('/wallet?request=true')}
            >
              OK
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibleModalOtp}
        title={'Nhập mã OTP'}
        footer={null}
        closable={true}
        onCancel={() => {
          setVisibleModalOtp(false)
        }}
        destroyOnClose
        className='model-otp'
      >
        <div
          className='otp-container'
        >
          <Input value={OTP} onChange={(e) => setOTP(e.target.value)} type='number' placeholder='Vui lòng nhập OTP' className='text-otp'/>
          <div
            className='btn-verify-otp'
            loading={loading}
            onClick={onClickVerify}
          >
            Xác nhận
          </div>
        </div>
      </Modal>
    </motion.div>
  )
}
