import React, { useEffect, useState } from 'react';
import { Typography, Avatar, message, Image, Button, Carousel } from 'antd';
import {
  ExclamationCircleOutlined,
  AlertFilled,
  CustomerServiceFilled,
  CheckOutlined,
  RightOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  LockOutlined,
  FileProtectOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import idCccdImg from '../../assets/id_cccd.svg';
import profileImg from '../../assets/profile.svg';
import creditcardImg from '../../assets/creditcard.svg';
import signatureImg from '../../assets/signature.svg';
import cameraImg from '../../assets/camera.svg';
import './ConfirmContract.scss';
import _ from 'lodash'
import api from '../../api';

export default function ConfirmContract() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [contract, setContract] = useState({})
  const [profile, setProfile] = useState({})

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      const { data: dataContract } = await api.get('/contracts/my-contract')

      const { data } = await api.get('/users/profile')

      if (data?.data) {
        setProfile(data.data)
      }

      if (dataContract) {
        setContract(dataContract)
      }
    } catch (error) {
      console.log('Get contract error: ', error)
    }
  }

  const confirmContractOnclick = async () => {
    try {
      const { data: dataContract } = await api.put('/contracts/update-pending')

      message.success('Đăng ký thành công, vui lòng chờ duyệt!')
      history.push('/debt-detail?cskh=true')
    } catch (error) {
      console.log('Confirm error: ', error)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push('/')} >
          <LeftOutlined className="arrow-icon" />
          <span>Trang chủ</span>
        </span>

        <Typography.Text className="header-title">Xác nhận khoản vay</Typography.Text>
      </div>
      <div className='body__container'>
        <p className='header__title text-center'>Thông tin khoản vay của bạn</p>
        <div className='contract-detail'>
          <div className='contract-detail__item'>
            <p className='contract-detail__item__title'>
              Số tiền vay:
            </p>
            <p className='contract-detail__item__value'>
              {contract?.amount?.toLocaleString('vi')} VND
            </p>
          </div>
          <div className='contract-detail__item'>
            <p className='contract-detail__item__title'>
              Thời gian vay:
            </p>
            <p className='contract-detail__item__value'>
              {contract?.times} tháng
            </p>
          </div>
          <div className='contract-detail__item-no-flex'>
            <div className='ne'>
              <p className='contract-detail__item__title'>
                Trả nợ hằng tháng:
              </p>
              <p className='contract-detail__item__value'>
                {contract?.amount_per_month?.toLocaleString('vi')} VND
              </p>
            </div>
            <i>(Đã bao gồm tiền lãi hằng tháng là {(contract?.amount * contract?.rate / 100).toLocaleString('vi')}đ)</i>
          </div>
          <div className='contract-detail__item'>
            <p className='contract-detail__item__title'>
              Ngân hàng nhận tiền:
            </p>
            <p className='contract-detail__item__value'>
              {profile?.info?.bank?.ngan_hang}
            </p>
          </div>
        </div>
        <Button className='btn-confirm' onClick={confirmContractOnclick}>Xác nhận</Button>
      </div>
    </motion.div>
  );
}
