import React, { useState, useRef, useEffect } from 'react';
import { Typography, Spin, message, Form, Input, Button } from 'antd';
import { UserOutlined, IdcardOutlined, UploadOutlined, LeftOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import Compress from 'react-image-file-resizer';
import converter from '../../utils/converterBase64ToBinary';
import './verify.scss';
import { BASE_URL } from '../../utils/constant';
import _ from 'lodash'

import svgFront from '../../assets/svg-front.svg'
import svgBack from '../../assets/svg-back.svg'
import svgFace from '../../assets/svg-face.svg'
import api from '../../api';
import { useHistory } from 'react-router-dom';

const Camera = ({ type, onDispatch, chooseImage, disabled }) => {
  const [loading, setLoading] = useState(false);

  const uploadImg = (e) => {
    try {
      const file = e.target.files[0]
      setLoading(true)
      if (!['image/png', 'image/jpeg'].includes(file.type)) {
        message.error(`Định dạng file không hợp lệ!`)
        return
      }
      
      Compress.imageFileResizer(
        file,
        480,
        480,
        'PNG', // compress format WEBP, JPEG, PNG
        100,
        0, // rotation
        async (uri) => {
          try {
            let formData = new FormData()
            const dataURI = converter(uri)
            formData.append('file', dataURI)

            const { data } = await api.post(
              '/upload',
              formData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    'access_token'
                  )}`,
                },
              }
            )

            const endpoint = data.secure_url
            onDispatch(endpoint)
          } catch (err) {
            message.error('Đã có lỗi trong việc upload ảnh. Vui lòng thử lại')
          }
        },
        'base64' // blob or base64 default base64
      )
    } catch (error) {
      console.log('Upload img error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const _getBaseUrl = () => {
    switch (type) {
      case 'front':
        return svgFront
      case 'back':
        return svgBack
    }
    return svgFace
  }

  const _getTitle = () => {
    switch (type) {
      case 'front':
        return 'Tải ảnh mặt trước CMND/CCCD'
      case 'back':
        return 'Tải ảnh mặt sau CMND/CCCD'
    }
    return 'Tải ảnh chụp mặt thật và cầm CMND/CCCD'
  }

  return (
    <div className='upload-container'>
      <div className='input-block'>
        <div className='input-title'>
          {_getTitle()}
        </div>
        <div className='input-btn-block'>
          <label className="custom-file-upload" disabled={disabled}>
            <input
              className='input-btn'
              type='file'
              accept="image/*"
              disabled={disabled}
              onChange={uploadImg}
            />
            <UploadOutlined className='upload-outline'/>
            Tải ảnh
          </label>
        </div>
      </div>
      <div className='img-block'>
        {
          loading ?
            <Spin spinning />
          :
            <img src={chooseImage || _getBaseUrl()} alt='loading...'/>
        }
      </div>
    </div>
  )
}

export default function StepOne(props) {
  const isNext = new URLSearchParams(props.location.search).get('isNext')
  const [form] = Form.useForm()
  const [front, setFront] = useState(null)
  const [back, setBack] = useState(null)
  const [face, setFace] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(true)

  const history = useHistory();

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/users/profile')
      
      const id_card = data?.data?.info?.id_card
      if (_.isEmpty(id_card)) {
        setIsUpdated(false)
      } else {
        setIsUpdated(true)
        setFront(id_card.id_front)
        setBack(id_card.id_back)
        setFace(id_card.selfie)
        form.setFieldsValue({
          fullname: id_card.fullname,
          number_id: id_card.number_id
        })
      }
    } catch (error) {
      console.log('Get info error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)

      if (!front || !back) {
        message.error('Ảnh chứng minh thư/CCCD là bắt buộc!')
        return
      }

      if (!face) {
        message.error('Ảnh chân dung là bắt buộc!')
        return
      }

      const { data } = await api.post('/users/update-info', {
        id_card: {
          fullname: values.fullname,
          number_id: values.number_id,
          selfie: face,
          id_front: front,
          id_back: back
        }
      })

      message.success('Tải lên thông tin thành công!')
      history.push(_getParam('/my-infomation'))
    } catch (error) {
      console.log('Call sigup error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const _validateRequired = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập thông tin!')
    } 
    return Promise.resolve()
  }

  const _getParam = (href) => {
    if (isNext === true || isNext === 'true') {
      return href + '?isNext=true'
    }
    return href
  }

  return (
    <motion.div
      // initial={{ opacity: 0.3, y: 10 }}
      // animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push(_getParam('/my-infomation'))}>
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>
        
        <Typography.Text className="header-title">Thông tin CMND/CCCD</Typography.Text>
      </div>
      
      <motion.div
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="user-img-container"
      >
        <div className='info-idcard-title' >
          Điền đầy đủ thông tin đúng và chính xác để được duyệt đơn vay nhanh chóng
        </div>
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError={true}
        >
          <div className='card-info-block'>
            <div className='field-block'>
              <label className='field-label required'>Tên đầy đủ</label>
                <Form.Item name='fullname'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--name'
                    size="large" 
                    placeholder="Vui lòng nhập tên của bạn" 
                    autoFocus={true}
                    disabled={isUpdated}
                    prefix={<UserOutlined />} 
                  />
                </Form.Item>
            </div>
            <div className='field-block'>
              <label className='field-label required'>Số CMND/CCCD</label>
                <Form.Item name='number_id'
                  rules={[
                    { validator: _validateRequired }
                  ]}
                >
                  <Input
                    className='form-body__input form-body__input--id'
                    size="large" 
                    placeholder="Vui lòng nhập số CMND/CCCD"
                    disabled={isUpdated}
                    prefix={<IdcardOutlined />} 
                  />
                </Form.Item>
            </div>

            <div className='warning-title'>
              Bạn phải có thẻ căn cước của mình và nội dung rõ ràng và dễ đọc. <br />
              Xin xác nhận đã bật dùng quyền chụp ảnh.
            </div>
          </div>
          <Camera disabled={isUpdated} type="front" chooseImage={front} onDispatch={(e) => setFront(e)} />
          <Camera disabled={isUpdated} type="back" chooseImage={back} onDispatch={(e) => setBack(e)} />
          <Camera disabled={isUpdated} type="face" chooseImage={face} onDispatch={(e) => setFace(e)} />
          <Button
            className="confirm-div"
            loading={loading}
            htmlType="submit"
            disabled={isUpdated}
          >
            <Typography.Text
              style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
            >
              Cập nhật
            </Typography.Text>
          </Button>
        </Form>
      </motion.div>
    </motion.div>
  )
}
