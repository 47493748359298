import React, { useEffect, useState } from 'react';
import { Typography, message, Image, Button } from 'antd';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import technologyFinance from '../../../assets/technology-finance.png';
import BlankDebt from '../../../components/BlankDebt';
import '../MyDebt/MyDebt.scss';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../../api';
import { CONFIG_KEY } from '../../../utils/constant';
import moment from 'moment';
import _ from 'lodash';

export default function MyDebt() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({})
  const [contract, setContract] = useState({})
  const [stk, setStk] = useState({})

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      const { data: dataContract } = await api.get('/contracts/my-contract')
      const { data: dataConfig } = await api.get('/config/all')
      const { data } = await api.get('/users/profile')

      const findStk = dataConfig?.data?.find(item => item.key === CONFIG_KEY.STK)

      if (findStk) {
        setStk(findStk.value)
      }

      if (data?.data) {
        setProfile(data.data)
      }

      if (dataContract && dataContract.status !== 'in_process') {
        setContract(dataContract)
      }
    } catch (error) {
      console.log('Get contract error: ', error)
    }
  }

  const copyOnclick = (text) => {
    navigator.clipboard.writeText(text)
    message.success("Copy thành công!")
  }

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push('/me')} >
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>

        <Typography.Text className="header-title">Khoản vay của tôi</Typography.Text>
      </div>
      <div className='body__container my-info'>
        <div className='my-debt'>
          <p className='text-align-center title'>Thông tin ngân hàng trả nợ</p>
          <div className='bank-info__block'>
            {/* Tên ngân hàng */}
            <div className='bank-info__item'>
              <div>
                <div className='bank-info__item--title'>
                  Tên ngân hàng:
                </div>
                <div className='bank-info__item--data'>
                  {stk?.bank_name}
                </div>
              </div>
              <Button className='btn-clipboard' onClick={() => copyOnclick('TP Bank')}>Sao chép</Button>
            </div>
            {/* Tài khoản ngân hàng */}
            <div className='bank-info__item'>
              <div>
                <div className='bank-info__item--title'>
                  Tài khoản ngân hàng:
                </div>
                <div className='bank-info__item--data'>
                  {stk?.bank_number}
                </div>
              </div>
              <Button className='btn-clipboard' onClick={() => copyOnclick('0754862458985')}>Sao chép</Button>
            </div>
            {/* Tên người nhận chuyển khoản */}
            <div className='bank-info__item'>
              <div>
                <div className='bank-info__item--title'>
                  Tên người nhận chuyển khoản:
                </div>
                <div className='bank-info__item--data'>
                  {stk?.name}
                </div>
              </div>
              <Button className='btn-clipboard' onClick={() => copyOnclick('TANG NGUYEN MINH TUNG')}>Sao chép</Button>
            </div>
            {/* Nội dung chuyển khoản */}
            <div className='bank-info__item'>
              <div>
                <div className='bank-info__item--title'>
                  Nội dung chuyển khoản:
                </div>
                <div className='bank-info__item--data'>
                  {!_.isEmpty(contract) && `Tra no HD ${contract.slug} thang ${moment().format('MM/YYYY')}`}
                </div>
              </div>
              <Button className='btn-clipboard' onClick={() => copyOnclick('202215101952')}>Sao chép</Button>
            </div>
          </div>
          <div className='blank-debt'>
            {
              _.isEmpty(contract) ?
                <>
                  <div className='blank-debt__image'>
                    <Image
                      width={220}
                      src={technologyFinance}
                    />
                  </div>
                  <div className='blank-debt__title text-align-center'>
                    Bạn chưa có hợp đồng vay được duyệt, vui lòng quay lại sau!
                  </div>
                  <Button className='blank-debt__button' onClick={() => history.push('/')}>
                    Yêu cầu vay tiền
                  </Button>
                </>
                :
                <div className='contract-container'>
                  <div className='warrning-block'>
                    <div className='ct-1'>Lưu ý</div>
                    <div className='ct-2'>Bạn cần phải thanh toán trả góp khoản vay</div>
                    <div className='ct-3'>trước ngày 12 hàng tháng</div>
                  </div>
                  <div className='list-ct-tt'>
                    Danh sách khoản vay đang hiệu lực
                  </div>
                  <div className='contract-detail'>
                    <div className='contract-detail__item'>
                      <p className='contract-detail__item__title'>
                        Số tiền vay:
                      </p>
                      <p className='contract-detail__item__value'>
                        {contract?.amount?.toLocaleString('vi')} VND
                      </p>
                    </div>
                    <div className='contract-detail__item'>
                      <p className='contract-detail__item__title'>
                        Thời gian vay:
                      </p>
                      <p className='contract-detail__item__value'>
                        {contract?.times} tháng
                      </p>
                    </div>
                    <div className='contract-detail__item'>
                      <p className='contract-detail__item__title'>
                        Ngày bắt đầu vay:
                      </p>
                      <p className='contract-detail__item__value'>
                        {moment(contract.created_at).format('DD/MM/YYYY')}
                      </p>
                    </div>
                    <div className='contract-detail__item'>
                      <p className='contract-detail__item__title'>
                        Ngày đáo hạn:
                      </p>
                      <p className='contract-detail__item__value'>
                        {moment(contract.created_at).add(contract.times, 'months').format('DD/MM/YYYY')}
                      </p>
                    </div>
                    <div className='contract-detail__item-no-flex'>
                      <div className='ne'>
                        <p className='contract-detail__item__title'>
                          Trả nợ hằng tháng:
                        </p>
                        <p className='contract-detail__item__value'>
                          {contract?.amount_per_month?.toLocaleString('vi')} VND
                        </p>
                      </div>
                      <i>(Đã bao gồm tiền lãi hằng tháng là {(contract?.amount * contract?.rate / 100).toLocaleString('vi')}đ)</i>
                    </div>
                    <div className='contract-detail__item block-btn'>
                      <div className='btn-rut-tien' onClick={() => history.push('/pay-my-debt')}>
                        <span>Lịch sử trả nợ</span>
                      </div>
                      <div className='btn-detail' onClick={() => history.push('/debt-detail')}>
                        <span>Xem chi tiết</span>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </motion.div>
  );
}
