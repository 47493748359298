import { useState, useEffect, useRef } from 'react';
import * as Screens from './screens';
import * as Components from './components';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider, useSelector, useDispatch } from 'react-redux';
import _store from './redux/store';
import useScreen from './hooks/useScreen';
import { Typography, Modal } from 'antd';
import * as actions from './redux/actions/auth';
import { DownloadOutlined } from '@ant-design/icons';
import api from './api';
import useNotification from './hooks/useNotification';
import NotFound from './components/NotFound';
const history = createBrowserHistory();

function App() {
  const { width } = useScreen();
  return (
    <>
      <Provider store={_store}>
        {width <= 1024 ? (
          <Router history={history}>
            <Routers />
          </Router>
        ) : (
          <NotFound />
        )}
      </Provider>
    </>
  );
}

const Routers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const localtion = useLocation();
  const { status, accessToken } = useSelector((state) => state._auth);
  const [token] = useState(localStorage.getItem('access_token'));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const { data } = await api.get('/users/profile');
          dispatch(actions.initialLogin(data.data));
        }
      } catch (err) {}
    })();
  }, [status, token]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (accessToken) {
  //         const { data } = await api.get('/users/profile');
  //         dispatch(actions.initialLogin(data.data));
  //       }
  //     } catch (err) {}
  //   })();
  // }, [localtion.pathname]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [localtion.pathname]);

  return (
    <>
      <div>
        {!status ? (
          <Switch>
            <Route path="/auth" component={Screens.Login} exact />
            <Route path="/auth/signup" component={Screens.Signup} exact />
            <Route path="/" component={Screens.Home} exact />
            <Route path="/me" component={Screens.User} exact />
            <Route path="/wallet" component={Screens.Wallet} exact />
          </Switch>
        ) : (
          <Switch>
            <Route path="/wallet" component={Screens.Wallet} exact />
            <Route path="/create-request" component={Screens.CreateRequest} exact />
            <Route path="/my-card" component={Screens.MyCard} exact />
            <Route
              path="/detail-profile"
              component={Screens.DetailProfile}
              exact
            />
            <Route path="/history" component={Screens.History} exact />
            <Route path="/me" component={Screens.User} exact />
            <Route path="/my-infomation" component={Screens.MyInfomation} exact />
            <Route path="/my-debt" component={Screens.MyDebt} exact />
            <Route path="/debt-detail" component={Screens.DetailContract} exact />
            <Route path="/pay-my-debt" component={Screens.PayMyDebt} exact />
            <Route path="/change-pass" component={Screens.ChangePass} exact />
            <Route path="/confirm-contract" component={Screens.ConfirmContract} exact />
            <Route
              path="/my-contract"
              component={Screens.MyContract}
              exact
            />
            <Route path="/withdraw" component={Screens.Withdraw} exact />
            <Route path="/contracts" component={Screens.Contract} exact />
            <Route path="/cskh" component={Screens.Cskh} exact />
            <Route path="/step-one" component={Screens.StepOne} exact />
            <Route path="/step-two" component={Screens.StepTwo} exact />
            <Route path="/step-three" component={Screens.StepThree} exact />
            <Route path="/step-four" component={Screens.StepFour} exact />
            <Route path="/step-five" component={Screens.StepFive} exact />
            <Route path="/" component={Screens.Home} exact />
            <Route
              path="/notifications"
              component={Screens.Notifications}
              exact
            />
          </Switch>
        )}
        {['/', '/wallet', '/services', '/me', '/auth', '/auth/signup'].includes(
          localtion.pathname
        ) && (
          <div
            style={{
              position: 'fixed',
              zIndex: 1000,
              bottom: 0,
              minWidth: '100%',
            }}
          >
            <Components.Navigator pathname={localtion.pathname} />
          </div>
        )}
      </div>
    </>
  );
};

export default App;
