import React, { useState, useRef, useEffect } from 'react';
import { Typography, Spin, message, Form, Input, Button, Select } from 'antd';
import { UserOutlined, IdcardOutlined, UploadOutlined, LeftOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import converter from '../../utils/converterBase64ToBinary';
import './verify.scss';
import { BASE_URL } from '../../utils/constant';
import _ from 'lodash'

import api from '../../api';
import { useHistory } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';

const { Option } = Select;

export default function StepFour(props) {
  const isNext = new URLSearchParams(props.location.search).get('isNext')
  const padRef = useRef();
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(true)
  const [signature, setSignature] = useState(null);

  const history = useHistory();

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/users/profile')
      
      const chu_ky = data?.data?.info?.chu_ky
      if (_.isEmpty(chu_ky)) {
        setIsUpdated(false)
      } else {
        setIsUpdated(true)
        setSignature(chu_ky)
      }
    } catch (error) {
      console.log('Get info error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)

      let formData = new FormData()
      const dataURI = converter(padRef.current.toDataURL())
      formData.append('file', dataURI);
      const { data: uploadRes } = await api.post(
        BASE_URL + '/upload',
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      )
      
      const { data } = await api.post('/users/update-info', {
        chu_ky: uploadRes.secure_url
      })

      setSignature(uploadRes.secure_url)
      setIsUpdated(true)
      message.success('Tải lên thông tin thành công!')
      history.push(_getParam('/my-infomation'))
    } catch (error) {
      console.log('Call sigup error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const _getParam = (href) => {
    if (isNext === true || isNext === 'true') {
      return href + '?isNext=true'
    }
    return href
  }

  return (
    <motion.div
      // initial={{ opacity: 0.3, y: 10 }}
      // animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push(_getParam('/my-infomation'))}>
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>
        
        <Typography.Text className="header-title">Chữ ký viết tay</Typography.Text>
      </div>
      
      <motion.div
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="user-img-container unset-padding"
      >
        <div className='info-idcard-title' >
          Vui lòng ký vào ô dưới đây
        </div>
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError={true}
        >
          <div className="signing">
            {
              isUpdated ?
                <img src={signature} alt='' className='sig-img'/>
              :
                <ReactSignatureCanvas
                  ref={padRef}
                  penColor="#666"
                  canvasProps={{
                    width: window.innerWidth || 300,
                    height: 300,
                    className: 'sigCanvas',
                  }}
                />
            }
          </div>
          
          <div className="refresh" disabled={isUpdated}>
            <Typography.Text
              onClick={() => {
                padRef.current.clear()
                setSignature(null)
              }}
              style={{ color: '#035993', margin: '5px 0 0 10px'}}
            >
              Làm mới
            </Typography.Text>
          </div>
          
          <Button
            className="confirm-div"
            loading={loading}
            htmlType="submit"
            disabled={isUpdated}
          >
            <Typography.Text
              style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
            >
              Cập nhật
            </Typography.Text>
          </Button>
        </Form>
      </motion.div>
    </motion.div>
  )
}
