import React, { useState, useRef, useEffect } from 'react';
import { Typography, Spin, message, Form, Input, Button } from 'antd';
import { UserOutlined, IdcardOutlined, UploadOutlined, LeftOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import Compress from 'react-image-file-resizer';
import converter from '../../utils/converterBase64ToBinary';
import './verify.scss';
import { BASE_URL } from '../../utils/constant';
import _ from 'lodash'

import svgFront from '../../assets/svg-front.svg'
import svgBack from '../../assets/svg-back.svg'
import svgFace from '../../assets/svg-face.svg'
import api from '../../api';
import { useHistory } from 'react-router-dom';

export default function StepFive(props) {
  const isNext = new URLSearchParams(props.location.search).get('isNext')
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [boXung, setBoXung] = useState([])

  const history = useHistory();

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/users/profile')
      
      const bo_xung = data?.data?.info?.bo_xung
      if (_.isEmpty(bo_xung)) {
        setBoXung([])
      } else {
        setBoXung(bo_xung)
      }
    } catch (error) {
      console.log('Get info error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onAddImg = (url) => {
    setBoXung([
      ...boXung,
      url
    ])
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)
      
      const { data } = await api.post('/users/update-info', {
        bo_xung: boXung
      })

      message.success('Tải lên thông tin thành công!')
      history.push(_getParam('/my-infomation'))
    } catch (error) {
      console.log('Call sigup error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const uploadImg = (e) => {
    try {
      const file = e.target.files[0]
      setLoading(true)
      if (!['image/png', 'image/jpeg'].includes(file.type)) {
        message.error(`Định dạng file không hợp lệ!`)
        return
      }
      
      Compress.imageFileResizer(
        file,
        480,
        480,
        'PNG', // compress format WEBP, JPEG, PNG
        100,
        0, // rotation
        async (uri) => {
          try {
            let formData = new FormData()
            const dataURI = converter(uri)
            formData.append('file', dataURI)

            const { data } = await api.post(
              '/upload',
              formData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    'access_token'
                  )}`,
                },
              }
            )

            const endpoint = data.secure_url
            onAddImg(endpoint)
          } catch (err) {
            message.error('Đã có lỗi trong việc upload ảnh. Vui lòng thử lại')
          }
        },
        'base64' // blob or base64 default base64
      )
    } catch (error) {
      console.log('Upload img error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const _getParam = (href) => {
    if (isNext === true || isNext === 'true') {
      return href + '?isNext=true'
    }
    return href
  }

  return (
    <motion.div
      // initial={{ opacity: 0.3, y: 10 }}
      // animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <span className='header-btn-back' onClick={() => history.push(_getParam('/my-infomation'))}>
          <LeftOutlined className="arrow-icon" />
          <span>Quay lại</span>
        </span>
        
        <Typography.Text className="header-title">Thông tin bổ xung</Typography.Text>
      </div>
      
      <motion.div
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="user-img-container body-add-info"
      >
        <div className='list-img-container'>
          <div className='info-idcard-title' >
            Điền đầy đủ thông tin đúng và chính xác để được duyệt đơn vay nhanh chóng
          </div>

          <div className='list-img'>
          {
            boXung.map((item, index) => {
              return <img src={item} key={index} alt='' />
            })
          }
          </div>
          <div className='btn-add-imt'>
            <div className='input-btn-block'>
              <label className="custom-file-upload">
                <input
                  className='input-btn'
                  type='file'
                  accept="image/*"
                  onChange={uploadImg}
                />
                <UploadOutlined className='upload-outline'/>
                Tải thêm dữ liệu
              </label>
            </div>
          </div>
        </div>

        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError={true}
        >
          <Button
            className="confirm-div full-size"
            loading={loading}
            htmlType="submit"
          >
            <Typography.Text
              style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
            >
              Cập nhật
            </Typography.Text>
          </Button>
        </Form>
      </motion.div>
    </motion.div>
  )
}
