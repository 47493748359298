import React, { useEffect } from 'react';
import { Typography, Avatar, message, Image, Button, Carousel } from 'antd';
import {
  ExclamationCircleOutlined,
  AlertFilled,
  CustomerServiceFilled,
  CheckOutlined,
  RightOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  LockOutlined,
  FileProtectOutlined
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import * as actions from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import connectCSKH from '../../utils/connectCSKH';
import tickAsset from '../../assets/tick.png';
import './User.scss';
import api from '../../api';

export default function User() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);

  useEffect(() => {
    loadProfile()
  }, []);

  const loadProfile = async () => {
    const { data } = await api.get('/users/profile');
    dispatch(actions.initialLogin(data.data));
  }

  const btnLogoutOnclick = () => {
    dispatch(actions.Logout())
    history.push('/auth')
  }

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider4">
            <div className="sl-text-container"></div>
          </div>
        </div>
      </Carousel>
      <div className='block__menu__list'>
        {/* //Thông tin của tôi */}
        <div 
          className='block__menu__list__item' 
          onClick={() => history.push('/my-infomation')}
        >
          <div className='item__first'>
            <div className='block__menu__list__item__icon'>
              <UserOutlined />
            </div>
            <div className='block__menu__list__item__text'>
              <Typography.Text>Thông tin của tôi</Typography.Text>
            </div>
          </div>
          <div className='item__second'>
            <div className='block__menu__list__item__action__icon'>
              <RightOutlined />
            </div>
          </div>
        </div>
        {/* Khoản vay của tôi */}
        <div 
          className='block__menu__list__item'
          onClick={() => history.push('/my-debt')}
        >
          <div className='item__first'>
            <div className='block__menu__list__item__icon'>
              <FileProtectOutlined />
            </div>
            <div className='block__menu__list__item__text'>
              <Typography.Text>Khoản vay của tôi</Typography.Text>
            </div>
          </div>
          <div className='item__second'>
            <div className='block__menu__list__item__action__icon'>
              <RightOutlined />
            </div>
          </div>
        </div>
        {/* Trả nợ của tôi */}
        <div 
          className='block__menu__list__item'
          onClick={() => history.push('/pay-my-debt')}
        >
          <div className='item__first'>
            <div className='block__menu__list__item__icon'>
              <MoneyCollectOutlined />
            </div>
            <div className='block__menu__list__item__text'>
              <Typography.Text>Trả nợ của tôi</Typography.Text>
            </div>
          </div>
          <div className='item__second'>
            <div className='block__menu__list__item__action__icon'>
              <RightOutlined />
            </div>
          </div>
        </div>
        {/* CSKH */}
        <div className='block__menu__list__item'
          onClick={connectCSKH}
        >
          <div className='item__first'>
            <div className='block__menu__list__item__icon'>
              <CustomerServiceFilled />
            </div>
            <div className='block__menu__list__item__text'>
              <Typography.Text>CSKH</Typography.Text>
            </div>
          </div>
          <div className='item__second'>
            <div className='block__menu__list__item__action__icon'>
              <RightOutlined />
            </div>
          </div>
        </div>
        {/* Đổi mặt khẩu */}
        <div 
          className='block__menu__list__item'
          onClick={() => history.push('/change-pass')}
        >
          <div className='item__first'>
            <div className='block__menu__list__item__icon'>
              <LockOutlined />
            </div>
            <div className='block__menu__list__item__text'>
              <Typography.Text>Đổi mặt khẩu</Typography.Text>
            </div>
          </div>
          <div className='item__second'>
            <div className='block__menu__list__item__action__icon'>
              <RightOutlined />
            </div>
          </div>
        </div>

        {/* Đăng xuất */}
        <Button
          className="block__menu__last__item"
          size="large"
          onClick={btnLogoutOnclick}
        >
          <Typography.Text className='block__menu__last__item--title'>
            Đăng xuất
          </Typography.Text>
        </Button>
      </div>
    </motion.div>
  );
}

const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: '1px solid rgba(0, 106, 176,0.3)',
      }}
      onClick={onClick}
      className="tab"
    >
      {icon}
      <Typography.Text className="tab-text" style={{ color: '#fff' }}>
        {title}
      </Typography.Text>
    </motion.div>
  );
};
const AlertVerify = ({ status }) => {
  const history = useHistory();

  return (
    <div className="alert-container">
      <div>
        <Typography.Text strong style={{ fontSize: 17, color: '#121212' }}>
          Xác thực tài khoản
        </Typography.Text>
        <div
          style={{
            borderBottom: '1px solid rgba(0, 106, 176,0.3)',
            height: 0.1,
            width: '100%',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 10px',
          }}
        >
          <div
            style={{
              flex: 1,
              minWidth: '30%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AlertFilled style={{ fontSize: 45, color: '#242ba6' }} />
          </div>
          <div>
            <Typography.Text
              style={{ fontSize: 16, fontWeight: 400, color: '#121212' }}
            >
              Bổ sung CMND/CCCD và chân dung để hoàn tất định danh
            </Typography.Text>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0px 10px',
          }}
        >
          <motion.div
            whileTap={{ scale: 0.9, opacity: 0.3 }}
            onClick={() => history.push('/verify')}
          >
            <Typography.Text
              strong
              style={{
                textAlign: 'right',
                fontWeight: '700',
                color: '#0022ba',
                fontSize: 17,
              }}
            >
              Xác thực ngay
            </Typography.Text>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
const IsVerify = ({ status }) => {
  return (
    <div
      className="verify"
      style={{
        background: status ? '#28ba00' : 'rgba(0,0,0,0.1)',
      }}
    >
      {status ? (
        <>
          <CheckOutlined
            style={{
              color: '#87d489',
              fontSize: 20,
            }}
          />
          <Typography.Text
            style={{
              flex: 1,
              textAlign: 'center',
              color: '#fff',
              fontWeight: 500,
            }}
          >
            Đã xác minh
          </Typography.Text>
        </>
      ) : (
        <>
          <ExclamationCircleOutlined
            style={{
              color: '#666',
              fontSize: 20,
            }}
          />
          <Typography.Text style={{ flex: 1, textAlign: 'center' }}>
            Chưa xác minh
          </Typography.Text>
        </>
      )}
    </div>
  );
};
